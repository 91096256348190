<script setup>
const activeKey = ref(0);

const questions = [
  {
    q: "1, MÃ QR LÀ GÌ?",
    a: `Mã qr là mã vạch hai chiều bao gồm một mô hình điểm ảnh có màu sắc tương phản cho phép mã hóa lên đến vài trăm ký tự: chữ, số, 
        ký tự đặc biệt, đường link URL… Các điện thoại thông minh và máy tính bảng có camera cũng như các thiết bị quét mã có thể nhận ra và giải mã chúng cực nhanh - không có gì đáng ngạc nhiên khi QR là viết tắt của Quick Response.`,
    image: "/images/qrcode-create/illustrate/Illu_Mini_Dynamic-1.png",
  },
  {
    q: "2, MÃ QR HOẠT ĐỘNG THẾ NÀO?",
    a: "Trong mã vạch 1D (hoặc mã vạch UPC), thông tin chỉ có thể được lưu trữ theo một hướng, tức là theo chiều ngang. Tuy nhiên, trong mã qr, thông tin có thể được lưu trữ cả theo chiều ngang lẫn chiều dọc. Điều này cho phép mã qrs lưu trữ nhiều thông tin hơn; cụ thể, một mã vạch UPC điển hình có thể lưu trữ từ 20 đến 25 ký tự trong khi mã qr có dung lượng tối đa 7,089 ký tự. Mã qr được tạo thành từ các <b>module</b> và có 40 phiên bản mã qr. Phiên bản 1 có 21 module và số lượng module tăng khi phiên bản tăng. Phiên bản 40 có 177 module.",
    image: "/images/qrcode-create/illustrate/QR-2.png",
  },
  {
    q: "3, QUÉT MÃ QR NHƯ THẾ NÀO?",
    a: `Giải mã qr không cần gì hơn điện thoại di động hoặc máy tính bảng, các hệ điều hành điện thoại thông minh đời mới đều đã hỗ trợ đọc mã qr thông qua camera của máy. Nếu điện thoại của người quét chưa hỗ trợ thì có thể tải về trình đọc mã qr có sẵn và có thể tải xuống từ tất cả các cửa hàng ứng dụng. Bạn có thể tìm trên kho ứng dụng AppStore hay Android Market từ khóa "QR scanner" “QRX” để tải ứng dụng quét mã qr cho iPhone hay smartphone dùng Android. 
        <br/>
        <br/>
        Để quét mã qr, chỉ cần khởi chạy camera chụp ảnh hoặc ứng dụng vừa tải về và chờ cho đến khi máy ảnh tự động phát hiện nó. Trong vài giây, nội dung được mã hoá và hiển thị trên màn hình. Để đánh giá cho chất lượng của ứng dụng, bạn có thể tham khảo xếp hạng đánh giá trung bình trong cửa hàng ứng dụng.`,
    image: "",
  },
  {
    q: "4, TÔI CÓ THỂ LÀM GÌ VỚI MÃ QR?",
    a: "Một mã qr có thể chứa đựng thông tin một địa chỉ web (URL), thời gian diễn ra một sự kiện, thông tin liên hệ (như vCard), địa chỉ email, tin nhắn SMS, nội dung ký tự văn bản hay thậm chí là thông tin định vị vị trí địa lý. Tùy thuộc thiết bị đọc mã qr mà bạn dùng khi quét, nó sẽ dẫn bạn tới một trang web, gọi đến một số điện thoại, xem một tin nhắn... Cách ứng dụng mã qr là vô cùng rộng. Đặc biệt khi mã hoá các URL có nhiều nội dung khác nhau. Chúng bao gồm trang chủ, trang web sản phẩm, video, phòng trưng bày ảnh, mã phiếu giảm giá, cuộc thi, hình thức.",
    image: "/images/qrcode-create/illustrate/Illu_TrackPerform-1.png",
  },
  {
    q: "5, TẠO MÃ QR NHƯ THẾ NÀO?",
    a: "Với QRX mã qr có thể được tạo ra chỉ với ba bước đơn giản. Lúc đầu, hãy chọn chức năng cho Mã qr của bạn. Thứ hai, nhập nội dung mà bạn muốn cung cấp cho khách hàng của mình và thiết kế lại nó một cách độc đáo bằng cách điều chỉnh màu sắc và tải lên một biểu tượng logo vào nó. Nếu bạn đã hoàn thành các bước trên, mã qr của bản đã hoạt động và tải nó xuống.",
    image: "/images/qrcode-create/illustrate/QR-3.png",
  },
  {
    q: "6, CÓ TỚI 21 LOẠI NỘI DUNG CHO MÃ QR TRÊN QRX.COM.VN, TÔI NÊN DÙNG LOẠI NÀO ?",
    a: `Tùy thuộc vào thông tin nội dung mà bạn muốn mã qr chứa đựng mà có thể chọn nội dung phù hợp nhất. Bạn có thể tìm hiểu tổng quan và cách sử dụng phù hợp nhất của từng loại 21 nội dung <a href='https://qrx.com.vn/giai-phap' target="_blank" class="text-[#ed7f22]">tại đây</a>.`,
    image: "",
  },
  {
    q: "7, TÔI CÓ THỂ THAY ĐỔI ĐƯỢC NỘI DUNG HOẶC LOẠI NỘI DUNG ĐÃ GẮN VÀO 1 MÃ QR KHÔNG?",
    a: `Đối với việc muốn chỉnh sửa, thay đổi được nội dung đã gắn với mã qr thì bạn cần sử dụng mã qr động để có thể thực hiện được điều này.<br/>
        Bạn có thể chỉnh sửa mã QR động theo các bước sau: <br/>
        Bước 1:  Nhấp vào Quản lý ở trên cùng để xem mã qr của bạn. <br/>
        Bước 2:  Nhấp vào mũi tên gắn với nút tải xuống ngay bên cạnh mã qr của bạn. <br/>
        Bước 3:  Chọn chỉnh sửa. <br/>
        Bước 4:  Thực hiện các thay đổi mong muốn và nhấp vào nút cập nhật để lưu chúng. <br/>
        Người dùng sẽ nhìn thấy nội dung cập nhật khi họ quét mã qr của bạn.`,
    image: "/images/qrcode-create/illustrate/QR-4.png",
  },
  {
    q: "8, MÃ QR TĨNH VÀ ĐỘNG KHÁC NHAU NHƯ THẾ NÀO ?",
    a: "Mã qr động có thể được chỉnh sửa ngay cả sau khi chúng đã được in. Điều này có nghĩa là bạn có thể thay đổi cả chức năng và nội dung của một mã qr động bất cứ lúc nào và thường xuyên như bạn muốn. Mã qr động sử dụng URL ngắn để chuyển hướng người dùng đến trang đích mong muốn của bạn. Điều này cho phép thu thập số liệu thống kê về số lần quét, địa điểm và ngày / thời gian, và hệ điều hành được sử dụng. Không thể chỉnh sửa mã qr tĩnh như email hoặc văn bản sau khi in và không thu thập bất kỳ thống kê quét nào. Họ cũng không mã hóa một URL ngắn và liên kết trực tiếp đến địa chỉ đích của bạn hoặc chứa toàn bộ thông tin được mã hóa.",
    image: "",
  },
  {
    q: "9, TẠO MÃ QR TĨNH NHƯ THẾ NÀO?",
    a: `qrx.com.vn là một công cụ quản lý mã qr sẽ giúp bạn tạo, thiết kế, quản lý và theo dõi tất cả mã qr của bạn cho các chiến dịch quảng cáo của bạn. <br/>
        Để tạo mã qr, hãy làm theo các bước sau: <br/>
        -	Bước 1:  Đăng nhập vào tài khoản qrx.com.vn của bạn. Nếu bạn không phải là người dùng trả phí, bạn có thể đăng ký dùng thử miễn phí 14 ngày. <br/>
        -	Bước 2: Chọn một danh mục có liên quan từ danh sách và nhấp vào loại mã qr mà bạn muốn tạo. Nếu bạn không thể tìm thấy mã qr có liên quan, bạn có thể sử dụng thanh tìm kiếm để tìm mã qr. <br/>
        -	Bước 3: Thêm nội dung và nhấp vào tạo mã qr. <br/>
        -	Bước 4: Thêm tên mã qr. <br/>
        -	Bước 5: Nhấp vào lưu và mã qr của bạn sẽ được tạo. <br/>
        -	Bước 6: Tùy chỉnh mã qr của bạn và cập nhật. <br/>
        -	Bước 7: Tải xuống mã qr này và đặt nó vào tài liệu quảng cáo của bạn. <br/>
        -	Bước 8: Kiểm tra mã qr của bạn bằng cách quét nó.`,
    image: "",
  },
  {
    q: "10, TẠO MÃ QR ĐỘNG NHƯ THẾ NÀO ?",
    a: `Trước tiên lưu ý rằng mã qr chỉ có thể chứa URL. Do đó, không phải tất cả mã qrs nào cũng là mã qr động. Mã qr có nội dung như văn bản đơn giản, thông tin về Wifi, Vcard đơn giản, địa chỉ email, số điện thoại, tin nhắn SMS và địa chỉ bitcoin chỉ có thể là mã qr tĩnh.
    Xem <a href="https://qrx.com.vn/blog/su-khac-biet-giua-dynamic-and-static-qr-codes-qr-dong-va-qr-tinh/" target="_blank" class="text-[#ed7f22]">sự khác biệt giữa mã qr động và mã qr tĩnh qrs</a><br/>.
        Để tạo một mã qr động, hãy làm theo các bước sau: <br/>
        -	Bước 1: Đăng nhập vào tài khoản QRX của bạn. Tài khoản này chỉ dành cho những khách hàng có sử dụng dịch vụ của QRX. <br/>
        -	Bước 2: Chọn một danh mục có liên quan nhấp vào loại Mã QR mà bạn muốn tạo. <br/>
        -	Bước 3: Thêm nội dung và nhấp vào tạo lô mã qr. <br/>
        -	Bước 4: Thêm các số liệu liên quan tạo lô mã qr. <br/>
        -	Bước 5: Nhấp vào lưu và xuất lô qr của bạn sẽ được tạo. <br/>
        -	Bước 6: Tải xuống mã qr này và đặt nó vào tài liệu quảng cáo của bạn. <br/>
        -	Bước 7: Kiểm tra mã qr của bạn bằng cách quét nó.`,

    image: "",
  },
  {
    q: "11, TÔI ĐÃ TẠO 1 MÃ QR TRÊN QRX VÀ GIỜ NÓ KHÔNG HOẠT ĐỘNG, TẠI SAO ?",
    a: `Nếu bạn tạo một mã qr được thiết kế riêng nhưng tính năng quét mã trên điện thoại thông minh của bạn không quét ra thì có thể là vì những lý do sau: <br/>
        - Kích thước lớn của logo: Giảm kích thước của logo để tăng kích thước loại bỏ module dữ liệu. <br/>
        - Mức độ sai lệch thấp: Nếu bạn thêm một logo, hãy đưa mức độ sai lệch lên High hoặc Very High để bù lại sự mất mát của các module. <br/>
        - Độ tương phản thấp: Độ tương phản giữa màu của mã qr (Eye và Data) và nền của nó có thể thấp. Làm sáng màu nền và làm tối các màu mã qr để tăng độ tương phản.`,
    image: "",
  },
  {
    q: "12, MÃ QR CÓ GIỚI HẠN VỀ SỐ LẦN QUÉT KHÔNG?",
    a: `Không có giới hạn về số lần quét trên cả mã qr động và mã qr tĩnh trong bất kỳ gói tài khoản nào: Free - Lite - Premium.`,
    image: "",
  },
  {
    q: "13, MÃ QR MIỄN PHÍ CÓ BỊ HẾT HẠN KHÔNG?",
    a: `Mã qr tĩnh sẽ vẫn hoạt động kể cả khi bạn đăng ký hoặc không đăng ký dịch vụ với qrx.com.vn. Tuy nhiên, bạn sẽ không thể chỉnh sửa nội dung hoặc theo dõi hoạt động quét của mã qr của bạn. <br/>  
        Các dữ liệu đích chỉ cần sử dụng mã qr tĩnh: <br/> 
        •	Sự kiện Lịch <br/> 
        •	Thông tin Vcard đơn giản <br/> 
        •	Số điện thoại <br/> 
        •	Địa chỉ Email <br/> 
        •	Đoạn text ngắn <br/> 
        •	Địa chỉ Bitcoin <br/> 
        •	Wifi <br/> 
        •	Tin nhắn văn bản`,
    image: "",
  },
  {
    q: "14, TÔI ĐÃ TẠO 1 MÃ QR TRÊN QROCDE-MARKETING.VN. NÓ SẼ HOẠT ĐỘNG TRONG BAO LÂU?",
    a: `Sử dụng qrx.com.vn bạn có thể tạo 2 loại mã qr: <b>mã qr tĩnh</b> và <b>mã qr động</b> <br/>  
        -	Mã qr tĩnh sẽ vẫn hoạt động kể cả khi bạn đăng ký hoặc không đăng ký dịch vụ với qrx.com.vn. Tuy nhiên, bạn sẽ không thể chỉnh sửa nội dung hoặc theo dõi hoạt động quét của mã qr của bạn. <br/> 
        -	Mã qr động sẽ hết hạn và không hoạt động khi được quét sau khi thời gian dùng thử miễn phí 14 ngày kết thúc. Để giữ cho chúng luôn hoạt động, bạn sẽ phải đăng ký một trong những gói tính năng của qrx.com.vn. Xem <a href="https://qrx.com.vn/bang-gia" target="_blank" class="text-[#ed7f22]">bảng giá</a> để biết thêm chi tiết.`,
    image: "",
  },
  {
    q: "15, LÀM THẾ NÀO ĐỂ TẠO MỘT SỐ LƯỢNG LỚN QRCODE?",
    a: `Để tạo ra qr với số lượng nhiều hơn bạn có thể đăng ký Tài khoản cao cấp của chúng tôi.`,
    image: "",
  },
  {
    q: "16, TÔI MUỐN BIẾT BẢNG GIÁ DỊCH VỤ THÌ XEM Ở ĐÂU?",
    a: `Bạn có thể xem và tham khảo bảng báo giá của qrx.com.vn ở <a href="https://qrx.com.vn/bang-gia" target="_blank" class="text-[#ed7f22]">đây</a>.`,
    image: "",
  },
  {
    q: "17, TÔI CÓ THỂ NÂNG CẤP HOẶC HẠ CẤP GÓI DỊCH VỤ ĐÃ MUA KHÔNG? ",
    a: `Bạn luôn có thể nâng cấp lên một gói cước cao cấp. Nếu bạn quyết định nâng cấp lên một gói cước cao cấp hơn, những thay đổi sẽ có hiệu lực với thời hạn của hợp đồng tiếp theo.
        Để nâng cấp hoặc hạ cấp tài khoản của bạn, hãy đi tới phần tổng quan về gói cước & giá cả trong tài khoản của bạn.`,
    image: "",
  },
  {
    q: "18, TÔI CÓ ĐƯỢC HOÀN LẠI TIỀN NẾU TÔI NÂNG CẤP TÀI KHOẢN SỬ DỤNG CỦA MÌNH KHÔNG? ",
    a: `Vâng. Ngay sau khi nâng cấp tài khoản của bạn, bạn sẽ nhận được một email bao gồm một hoá đơn mới. Số tiền này sẽ tự động giảm theo thời gian còn lại của gói tài khoản trước đó của bạn.`,
    image: "",
  },
  {
    q: "19, CÓ NHỮNG TÍNH NĂNG GÌ TRONG TÀI KHOẢN DÙNG THỬ?",
    a: `Phiên bản dùng thử miễn phí cho phép bạn truy cập vào tất cả các tính năng của qrx.com.vn ngoại trừ một số tính năng của doanh nghiệp như tạo ra hàng loạt, ghi nhãn trắng và API. Trong thời gian dùng thử miễn phí, bạn có thể: <br/> 
        • Tạo mã qr không giới hạn. <br/> 
        • Sử dụng tất cả các khả năng thiết kế. <br/> 
        • Truy cập tất cả các tính năng phân tích và báo cáo. <br/> 
        Truy cập các tính năng tiên tiến như truy cập nhiều người dùng, bảo vệ mật khẩu, URL ngắn tùy chỉnh.`,
    image: "",
  },
  {
    q: "20, TÔI CÓ THỂ GIA HẠN TRONG THỜI GIAN DÙNG THỬ MIỄN PHÍ KHÔNG?",
    a: `Vâng. Chúng tôi có thể kéo dài thời gian dùng thử miễn phí 7 ngày. Để yêu cầu gia hạn thời gian dùng thử miễn phí, vui lòng viết cho chúng tôi một email bao gồm địa chỉ email đăng nhập hoặc ID khách hàng của bạn.`,
    image: "",
  },
  {
    q: "21, TÔI CÓ THỂ THANH TOÁN THEO THÁNG/QUÝ KHÔNG?",
    a: `Vâng. qrx.com.vn là công cụ duy nhất cung cấp cho bạn tính linh hoạt trong việc đăng ký hàng tháng, hàng quý, nửa năm và hàng năm. Thời gian gói thuê bao của bạn càng dài, thì bạn sẽ càng tiết kiệm chi phí hơn.
    Lựa chọn thời gian sẽ có sẵn tại thời điểm thanh toán. Xem tại <a href="https://qrx.com.vn/bang-gia" target="_blank" class="text-[#ed7f22]">bảng giá</a>.`,
    image: "",
  },
];
</script>

<template>
  <section>
    <div class="bg-white p-14 max-lg:px-4 max-lg:py-6 rounded-[24px]">
      <h2 class="text-center">Các câu hỏi thường gặp FAQs</h2>

      <div class="border-2 border-solid border-[#999] max-lg:border-none rounded-3xl mt-14 max-lg:mt-6">
        <a-collapse
          v-model:activeKey="activeKey"
          :bordered="false"
          expandIconPosition="right"
          accordion
          class="bg-white ant-collapse-borderless--custom"
        >
          <a-collapse-panel v-for="(question, index) in questions" :key="index" :header="question.q">
            <div v-html="question.a"></div>
            <div class="flex justify-center mt-[40px]" v-if="question.image !== ''">
              <nuxt-picture
                loading="lazy"
                :src="question.image"
                :img-attrs="{ alt: question.q, width: 450, height: auto }"
              />
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </section>
</template>
